<template>
  <section class="restricted-map-container">
    <v-progress-linear
      active
      background-opacity="1"
      buffer-value="100"
      height="4"
      indeterminate
      value="1000"
      color="#59b570"
      v-if="isFiltering"
      class="v-progress-linear"
    ></v-progress-linear>
    <div v-if="isLoading && !passwordValidated" class="loader">
      <v-progress-circular indeterminate color="#59b570"></v-progress-circular>
    </div>
    <RestrictedMap
      :selectedEmsp="selectedEmsp"
      :isLoading="isLoading && !passwordValidated"
      :emspOption="emspOption"
    ></RestrictedMap>
    <PasswordModal
      v-if="isPassword && !passwordValidated"
      @validate="passwordValidated = true"
    />
  </section>
</template>

<script>
import RestrictedMap from "@/components/map/RestrictedMap";
import PasswordModal from "@/components/map/PasswordModal";
export default {
  name: "Restricted-Map",
  components: {
    RestrictedMap,
    PasswordModal
  },
  data() {
    return {
      selectedEmsp: null,
      passwordValidated: false
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["station/isStationLoading"];
    },
    isPassword() {
      return this.$store.getters["carte/password"] !== null;
    },
    isFiltering() {
      return this.$store.getters["emsp/isFiltering"];
    },
    emspOption() {
      let currentEmspOptions = null;
      let abonnement, prix_badge, prix_transactions;
      if (this.selectedEmsp && this.$store.getters["emsp/emspOption"].length) {
        this.$store.getters["emsp/emspOption"].forEach(emspOption => {
          if (emspOption && emspOption.acf.slug === this.selectedEmsp.emsp_id)
            currentEmspOptions = emspOption;
        });
        if (currentEmspOptions) {
          abonnement = currentEmspOptions.acf.abonnement;
          prix_badge = currentEmspOptions.acf.prix_badge;
          prix_transactions = currentEmspOptions.acf.prix_transactions;
        }
        return {
          abonnement,
          prix_badge,
          prix_transactions
        };
      }
      return {};
    }
  },
  async created() {
    const routeToken = this.$route.params.token;
    if (routeToken)
      try {
        await this.$store.dispatch("station/getRestrictedStations", routeToken);
      } catch (e) {
        this.$router.push("/");
      }
  },
  async mounted() {
    try {
      await this.$store.dispatch("carte/getPassword", this.$route.params.token);
      if (!this.$store.getters["carte/password"]) {
        this.passwordValidated === true;
      }
    } catch (e) {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss">
.v-progress-linear {
  position: absolute;
  top: 0;
  z-index: 1000;
}
.restricted-map-container {
  width: 100%;
  height: 100vh;
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    background: #2c2c32;

    width: 100%;
    height: 100%;
    .v-progress-circular {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>

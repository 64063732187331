<template>
  <div class="admin">
    <router-view></router-view>
    <v-dialog
      v-model="dialog"
      class="password-modal"
      persistent
      max-width="320"
    >
      <v-card>
        <v-card-title>
          <span
            >Entrez le mot de passe <br />
            administrateur</span
          >
        </v-card-title>
        <v-form
          v-model="valid"
          @submit.prevent="validate"
          lazy-validation
          ref="form"
        >
          <v-card-text>
            <v-text-field
              class="mb-2"
              v-model="passwordInput"
              :rules="passwordRule"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn type="submit">Valider</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      adminPassword: "consult",
      passwordRule: [v => v === this.adminPassword || "Mauvais mot de passe"],
      passwordInput: null,
      valid: false
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["admin/isAdmin"];
    }
  },
  methods: {
    validate() {
      let isValid = this.$refs.form.validate();
      if (isValid) {
        this.$store.commit("admin/SET_IS_ADMIN", true);
        this.passwordInput = null;
        this.dialog = false;
      }
    }
  },
  watch: {
    isAdmin: function(val) {
      if (!val) {
        this.dialog = true;
      }
    }
  }
};
</script>

<style lang="scss">
.admin {
  background: #e2e2e2;
  width: 100%;
}
</style>

<template>
  <v-container class="admin-consultation">
    <div class="header d-flex justify-space-between">
      <h3>Consultation</h3>
      <img
        @click="$emit('close')"
        src="@/assets/map/ic_close.svg"
        alt="icone fermeture"
        class="close"
      />
    </div>
    <v-container>
      <span v-if="previousConsultUrl"
        >Lien de la consultation précédemment crée:
        <a :href="previousConsultUrl">{{ previousConsultUrl }}</a></span
      >
      <v-form @submit.prevent="validate" ref="form">
        <v-row>
          <v-col cols="6">
            <v-text-field
              :rules="requiredRule"
              placeholder="Nom de la consultation"
              v-model="consultation.name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>En tête: </span>
            <quill-editor
              :content="consultation.header"
              :options="editorOption"
              @change="onWysiwygChange($event, 'header')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>En tête popup: </span>
            <quill-editor
              :content="consultation.popup_header"
              :options="editorOption"
              @change="onWysiwygChange($event, 'popup_header')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-radio-group
              :rules="requiredCheckbox"
              v-model="consultation.is_free_choice"
              label="Type de consultation: "
              row
            >
              <v-radio label="Choix prédéfinis" :value="false"></v-radio>
              <v-radio label="Choix libre" :value="true"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" v-if="!consultation.is_free_choice">
            <v-radio-group
              :rules="requiredCheckbox"
              v-model="consultation.has_custom_address"
              label="Adresse customisable : "
              row
            >
              <v-radio label="Optionnelle" :value="true"></v-radio>
              <v-radio label="Cachée" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="!consultation.is_free_choice">
          <v-col cols="6">
            <span>Couleur POI</span>
            <v-color-picker
              mode="hexa"
              hide-mode-switch
              hide-canvas
              v-model="consultation.colors.poi"
            ></v-color-picker>
          </v-col>
          <v-col cols="6">
            <span>Couleur texte cluster</span>
            <v-color-picker
              hide-canvas
              mode="hexa"
              hide-mode-switch
              v-model="consultation.colors.text"
            ></v-color-picker>
          </v-col>
        </v-row>
        <v-row
          v-if="consultation.is_free_choice === false"
          class="custom-point-select mb-2"
        >
          <v-col cols="6">
            <span class="caption"
              >Entrez une latitude et une longitude puis validez</span
            >
            <v-text-field
              placeholder="Latitude"
              type="number"
              v-model="custom_point_lat"
            ></v-text-field>
            <v-text-field
              placeholder="Longitute"
              type="number"
              v-model="custom_point_lng"
            ></v-text-field>
            <v-btn @click="addCustomPoint">Ajouter le point</v-btn>
          </v-col>
          <!-- <v-col cols="2" align-self="center" >
            <v-btn @click="addCustomPoint" icon class="d-block ma-auto">
              <v-icon>fas fa-arrow-right</v-icon>
            </v-btn>
          </v-col> -->
          <v-col cols="4" offset="2">
            <ul v-if="selectedPoints.length">
              <li
                v-for="(point, i) in selectedPoints"
                :key="
                  point.data.geometry.coordinates[0] +
                    point.data.geometry.coordinates[1] +
                    i
                "
              >
                <span class="caption mr-4"
                  >Lat: {{ point.data.geometry.coordinates[1] }}, Long:
                  {{ point.data.geometry.coordinates[0] }}</span
                >
                <v-btn
                  x-small
                  icon
                  color="error"
                  @click="removePoint(point, i)"
                >
                  <v-icon>far fa-trash-alt</v-icon>
                </v-btn>
              </li>
            </ul>
            <p class="caption" v-else>Pas de points sélectionnés</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" align-self="center">
            <v-autocomplete
              v-model="consultation.displayed_cpos"
              :items="cpo_list"
              item-text="name"
              item-value="cpo_id"
              dense
              chips
              small-chips
              deletable-chips
              label="CPOS"
              multiple
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label for="">Téléphone :</label>
            <v-radio-group row v-model="consultation.phone_restriction">
              <v-radio
                v-for="{ label, value } in phoneRestrictionList"
                :key="value"
                :label="label"
                :value="value"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" align-self="start">
            <v-checkbox
              v-model="hasRestrictedArea"
              label="Ajouter une zone de restriction"
            ></v-checkbox>
            <div v-if="hasRestrictedArea">
              <span class="caption"
                >Les points de la zone de restriction doivent se suivre</span
              >
              <v-row class="resticted-area-input">
                <v-textarea
                  name="input-7-1"
                  :placeholder="
                    `Entrez les coordonnées des points de la zone de restriction sous forme de tableaux de coordonnées [longitute, latitude] tous les tableaux de coordonnées doivent se suivre et être séparés par un point virgule.`
                  "
                  hint="Exemple: [longitute, latitude], [longitute, latitude], [longitute, latitude], [longitute, latitude]"
                  v-model="restrictedAreaStr"
                ></v-textarea>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-checkbox
              v-model="has_electric_car_buyed"
              label="Champ voiture électrique propriétaire"
            ></v-checkbox>
            <v-textarea
              rows="2"
              v-if="has_electric_car_buyed"
              type="text"
              v-model="consultation.electric_car_buyed"
            ></v-textarea>
            <v-checkbox
              v-model="has_electric_car_ordered"
              label="Champ voiture électrique commandée"
            ></v-checkbox>
            <v-textarea
              rows="2"
              v-if="has_electric_car_ordered"
              type="text"
              v-model="consultation.electric_car_ordered"
            ></v-textarea>

            <v-checkbox
              v-model="has_parking"
              label="Champ parking"
            ></v-checkbox>
            <v-textarea
              rows="2"
              v-if="has_parking"
              type="text"
              v-model="consultation.parking"
            ></v-textarea>

            <v-checkbox
              v-model="has_recharge_point"
              label="Champ point de recharge"
            ></v-checkbox>
            <v-textarea
              rows="2"
              v-if="has_recharge_point"
              type="text"
              v-model="consultation.recharge_point"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>Conditions d'utilisation : </span>
            <quill-editor
              :content="consultation.conditions"
              :options="editorOption"
              @change="onWysiwygChange($event, 'conditions')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>RGPD: </span>
            <quill-editor
              :content="consultation.rgpd"
              :options="editorOption"
              @change="onWysiwygChange($event, 'rgpd')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>Sur l'honneur: </span>
            <quill-editor
              :content="consultation.honor"
              :options="editorOption"
              @change="onWysiwygChange($event, 'honor')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>Pièces justificative</span>
            <quill-editor
              :content="consultation.documents"
              :options="editorOption"
              @change="onWysiwygChange($event, 'documents')"
            />
            <!-- <quill-editor
              :content="consultation.documents"
              :options="editorOption"
              @change="onWysiwygChange($event, 'documents')"
            /> -->
          </v-col>
        </v-row>
        <v-btn :loading="isLoading" type="submit">Valider</v-btn>
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
export default {
  components: {},
  data: () => ({
    cpo_list: [],
    consultation: {
      header: "",
      name: "",
      colors: {
        poi: {
          hex: "#FF00FF",
          hexa: "#FF00FFFF",
          rgba: { r: 255, g: 0, b: 255, a: 1 },
          hsla: {
            h: 300,
            s: 1,
            l: 0.5,
            a: 1
          },
          hsva: { h: 300, s: 1, v: 1, a: 1 }
        },
        text: {
          hex: "#FF00FF",
          hexa: "#FF00FFFF",
          rgba: { r: 255, g: 0, b: 255, a: 1 },
          hsla: {
            h: 300,
            s: 1,
            l: 0.5,
            a: 1
          },
          hsva: { h: 300, s: 1, v: 1, a: 1 }
        }
      },
      is_free_choice: false,
      displayed_cpos: [],
      electric_car_buyed:
        "Vous êtes propriétaire d'une voiture électrique ou hybride rechargeable",
      electric_car_ordered:
        "Vous avez commandé une voiture électrique ou hybride rechargeable",
      parking: "Vous ne disposez pas d'une place de parking privée",
      recharge_point:
        "Vous ne disposez pas d'un point de recharge à proximité du domicile ou du lieu de travail",
      phone_restriction: 0
      // restricted_area: [{lat: 1, lng: 1},{lat: 2, lng: 2},{lat: 3, lng: 3},{lat: 4, lng: 4}],
    },
    custom_point_lat: null,
    custom_point_lng: null,
    selectedPoints: [],
    hasRestrictedArea: false,
    restrictedAreaStr: "",

    requiredRule: [v => !!v || "Veuillez remplir ce champ"],
    requiredCheckbox: [
      v => v === true || v === false || "Veuillez remplir ce champ"
    ],
    isLoading: false,
    previousConsultUrl: null,
    has_electric_car_buyed: false,
    has_electric_car_ordered: false,
    has_parking: false,
    has_recharge_point: false,
    editorOption: {
      // Some Quill options...
    },
    phoneRestrictionList: [
      {
        label: "Obligatoire",
        value: 0
      },
      {
        label: "Optionnel",
        value: 1
      },
      {
        label: "Caché",
        value: 2
      }
    ]
  }),
  props: {
    selectedConsultation: {
      type: Object,
      default: () => ({})
    }
  },
  async created() {
    this.cpo_list = await this.$store.dispatch("admin/getAllCpos");
    if (this.selectedConsultation) {
      this.setSelectedConsultation();
      // this.consultation = JSON.parse(JSON.stringify(this.selectedConsultation))
    }
  },
  methods: {
    onWysiwygChange({ html }, model) {
      this.consultation[model] = html;
    },
    addCustomPoint() {
      if (!this.custom_point_lat || !this.custom_point_lng) return;

      this.selectedPoints.push({
        data: {
          geometry: {
            coordinates: [
              Number(this.custom_point_lng),
              Number(this.custom_point_lat)
            ],
            type: "Point"
          }
        }
      });
      this.custom_point_lat = null;
      this.custom_point_lng = null;
      // this.selectedPoints.push({lng: this.custom_point_lng, lat: this.custom_point_lat})
    },
    async validate() {
      let isValid = this.$refs.form.validate();

      if (!this.consultation.is_free_choice && !this.selectedPoints.length) {
        return this.$toast.error("Veuillez ajouter des choix prédéfinis");
      }
      if (this.hasRestrictedArea) {
        let restrictedAreaPoints = this.restrictedAreaStr.split(";");
        if (!restrictedAreaPoints.length)
          return this.$toast.error(
            "Veuillez remplir les points de la zone de restriction"
          );
        try {
          restrictedAreaPoints = restrictedAreaPoints.map(point =>
            JSON.parse(point)
          );
          this.consultation.restricted_area = [...restrictedAreaPoints];
        } catch (error) {
          return this.$toast.error(
            "Veuillez vérifier le format de la zone de restriction"
          );
        }
      }
      if (this.consultation.is_free_choice)
        this.consultation.consultation_stations = null;
      if (isValid) {
        if (!this.has_electric_car_buyed)
          this.consultation.electric_car_buyed = null;
        if (!this.has_electric_car_ordered)
          this.consultation.electric_car_ordered = null;
        if (!this.has_parking) this.consultation.parking = null;
        if (!this.has_recharge_point) this.consultation.recharge_point = null;
        this.isLoading = true;
        try {
          if (this.selectedConsultation) {
            let points = this.selectedPoints.map(point => ({
              ...point,
              id_consultation: this.selectedConsultation.id
            }));
            await this.$store.dispatch("consultation/updateConsultation", {
              consultation: this.consultation,
              points
            });
          } else {
            if (!this.consultation.is_free_choice)
              this.consultation.consultation_stations = {
                data: this.selectedPoints
              };
            await this.$store.dispatch(
              "consultation/insertConsultation",
              this.consultation
            );
          }
          this.$toast.success(
            "La consultation a bien été " +
              (this.selectedConsultation ? "modifiée" : "créée")
          );
          // this.previousConsultUrl = window.location.origin+'/consultation/' +response.insert_consultation.returning[0].id
          this.$refs.form.reset();
          this.isLoading = false;
          this.$emit("close");
        } catch (error) {
          this.$toast.error("Une erreur est survenue");
          this.isLoading = false;
        }
      }
    },
    setSelectedConsultation() {
      this.consultation.name = this.selectedConsultation.name;
      this.consultation.id = this.selectedConsultation.id;
      this.consultation.is_free_choice = this.selectedConsultation.is_free_choice;
      this.consultation.displayed_cpos = this.selectedConsultation.displayed_cpos;
      this.consultation.header = this.selectedConsultation.header;
      this.consultation.popup_header = this.selectedConsultation.popup_header;
      this.consultation.rgpd = this.selectedConsultation.rgpd;
      this.consultation.conditions = this.selectedConsultation.conditions;
      this.consultation.has_custom_address = this.selectedConsultation.has_custom_address;
      this.consultation.honor = this.selectedConsultation.honor;
      this.consultation.documents = this.selectedConsultation.documents;
      this.consultation.phone_restriction = this.selectedConsultation.phone_restriction;
      this.consultation.colors = this.selectedConsultation.colors;
      this.selectedPoints = this.selectedConsultation.consultation_stations.map(
        station => ({ id: station.id, data: station.data })
      );

      if (this.selectedConsultation.electric_car_ordered)
        this.consultation.electric_car_ordered = this.selectedConsultation.electric_car_ordered;
      if (this.selectedConsultation.electric_car_buyed)
        this.consultation.electric_car_buyed = this.selectedConsultation.electric_car_buyed;
      if (this.selectedConsultation.parking)
        this.consultation.parking = this.selectedConsultation.parking;
      if (this.selectedConsultation.recharge_point)
        this.consultation.recharge_point = this.selectedConsultation.recharge_point;
      // this.consultation.electric_car_buyed = this.selectedConsultation.electric_car_buyed
      // this.consultation.parking = this.selectedConsultation.parking
      // this.consultation.recharge_point = this.selectedConsultation.recharge_point
      this.has_electric_car_buyed = !!this.selectedConsultation
        .electric_car_buyed;
      this.has_electric_car_ordered = !!this.selectedConsultation
        .electric_car_ordered;
      this.has_parking = !!this.selectedConsultation.parking;
      this.has_recharge_point = !!this.selectedConsultation.recharge_point;

      if (
        this.selectedConsultation.restricted_area &&
        this.selectedConsultation.restricted_area.length
      ) {
        this.restrictedAreaStr = "";
        this.selectedConsultation.restricted_area.forEach((areaPoint, i) => {
          this.restrictedAreaStr +=
            JSON.stringify(areaPoint) +
            (this.selectedConsultation.restricted_area.length - 1 === i
              ? ""
              : ";");
        });
        this.hasRestrictedArea = true;
      }
    },
    async removePoint(point, i) {
      if (point.id)
        await this.$store.dispatch("consultation/removeConsultationPoint", {
          point,
          consultation_id: this.selectedConsultation.id
        });
      this.selectedPoints.splice(i, 1);
    }
  }
};
</script>

<style lang="scss">
.admin-consultation {
  // height: 100vh;
  .custom-point-select {
    border: 1px solid black;
    border-radius: 10px;
  }
  // .v-color-picker__preview {
  //   display: none;
  // }
}
</style>

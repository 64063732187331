<template>
  <section class="gestion-consultations pa-4">
    <v-row justify="center">
      <v-col cols="1">
        <p @click="$emit('close')" class="close">Retour</p>
      </v-col>
      <v-col cols="11">
        <h3 class="text-center">Gestion des consultations</h3>
      </v-col>
    </v-row>
    <v-container class="consultation-list">
      <v-btn @click="isConsultationModalOpen = true" class="ml-auto d-block mb-4">Ajouter une consultation</v-btn>
      <v-data-table
        v-if="consultations"
        :headers="headers"
        :items="consultations"
        :items-per-page="10"
        class="elevation-1"
      >
      <template v-slot:item.consultation_stations="{ item }">
        <span v-for="station in item.consultation_stations" :key="station.id">{{station.data.geometry.coordinates}} </span>
      </template>
      <template v-slot:item.displayed_cpos="{ item }">
        <span v-for="(cpos, i) in item.displayed_cpos" :key="cpos">{{cpos}}{{item.displayed_cpos.length - 1 === i ? null : ' | '}}</span>
      </template>
      <template v-slot:item.restricted_area="{ item }">
        <span v-for="(zone, i) in item.restricted_area" :key="zone.join() + i">{{zone}} </span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="blue" class="close mr-2" @click="openConsultation(item)">fas fa-eye</v-icon>            
          </template>
          <span>Accéder a la consultation</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="green" class="close mr-2" @click="update(item)">fas fa-pen</v-icon>            
          </template>
          <span>Modifier la consultation</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="red" class="close" @click="openDeleteModal(item)">fas fa-trash</v-icon>
          </template>
          <span>Supprimer la consultation</span>
        </v-tooltip>
      </template>
      </v-data-table>
    </v-container>

    <v-dialog persistent v-if="isConsultationModalOpen" v-model="isConsultationModalOpen">
      <v-card>
        <Consultation @close="handleClose" :selectedConsultation="selectedConsultation"></Consultation>

      </v-card>
    </v-dialog>
    <ConfirmModal title="Supprimer la consultation ?" :isModalOpen="isDeleteModalOpen" @close="isDeleteModalOpen = false; selectedConsultation = null" @validate="deleteConsultation"></ConfirmModal>
  </section>
</template>

<script>
import Consultation from '@/components/admin/CreateConsultation'
import ConfirmModal from '@/components/modal/ConfirmModal'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      consultations: null,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Nom', value: 'name' },
        { text: 'Stations', value: 'consultation_stations' },
        { text: 'Cpos', value: 'displayed_cpos' },
        { text: 'Zone de restriction', value: 'restricted_area' },
        { text: 'Actions', value: 'action' },
        // { text: 'Protein (g)', value: 'protein' },
        // { text: 'Iron (%)', value: 'iron' },
      ],
      isConsultationModalOpen: false,
      selectedConsultation: null,
      isDeleteModalOpen: false
    }
  },
  components: {
    Consultation,
    ConfirmModal
  },
  async created () {
    this.consultations = await this.getConsultationList();
  },
  methods: {
    ...mapActions('consultation', ['getConsultationList']),
    update(consult) {
      this.selectedConsultation = consult
      this.isConsultationModalOpen = true
    },
    openDeleteModal(consult) {
      this.selectedConsultation = consult
      this.isDeleteModalOpen = true
    },
    async deleteConsultation() {
      this.isDeleteModalOpen = false;
      await this.$store.dispatch('consultation/deleteConsultation', this.selectedConsultation.id)
      this.consultations = this.consultations.filter(consult => consult.id !== this.selectedConsultation.id )
      this.selectedConsultation = null
    },
    openConsultation(consultation) {
      window.open(`${window.location.origin}/consultation/${consultation.id}`, '_blank');
    },
    async handleClose() {
      this.isConsultationModalOpen = false; 
      this.selectedConsultation = null
      this.consultations = await this.getConsultationList()
    }
  },
}
</script>

<style lang="scss">
  .gestion-consultations {
    height: 100vh;
    .action-btn {
      width: 25px;
      height: 25px !important;
    }
  }
</style>

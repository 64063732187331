<template>
  <div class="password-modal-wrapper">
    <div class="password-modal">
      <h3>Cette page nécessite une authentificfation</h3>
      <form @submit.prevent="handleCheck">
        <span v-if="error" class="error-txt">Mot de passe incorrect</span>
        <v-text-field
          v-model="password"
          type="password"
          placeholder="Mot de passe"
        />
        <v-btn type="submit">Accéder à la carte</v-btn>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      password: "",
      error: false
    };
  },
  methods: {
    handleCheck() {
      if (this.password === this.$store.getters["carte/password"]) {
        this.error = false;
        this.$emit("validate");
      } else {
        this.error = true;
      }
    }
  }
};
</script>

<style lang="scss">
.password-modal-wrapper {
  backdrop-filter: blur(10px);

  position: fixed;
  background-color: rgba(0, 0, 0, 0.274);
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .error-txt {
    margin-top: 20px;
    background: transparent !important;
    color: indianred;
  }
  .password-modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #21212179;
    form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      .v-input,
      .v-btn {
        width: 100%;
      }
    }
  }
}
</style>

<template>
  <section class="charging-station">
    <section
      class="station-header px-4 py-4"
      :style="{
        backgroundColor: selectedPoint.roaming
          ? poisColors.roaming
          : poisColors.pois,
        color: poisColors && poisColors.text ? poisColors.text : '#141417'
      }"
    >
      <div class="emsp-close d-flex justify-space-between mb-2">
        <h4>
          {{ selectedEmsp }}
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon
                class="icon mx-5"
                x-small
                :color="poisColors.text"
                v-on="on"
                @click="isProblemModalOpen = !isProblemModalOpen"
                >fas fa-exclamation-triangle</v-icon
              >
            </template>
            <span>Signaler un problème</span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon
                v-show="selectedPoint.emsp !== 'null' && true"
                class="icon mr-5"
                x-small
                v-on="on"
                :color="poisColors.text"
                @click.stop="showInfos"
                >fas fa-info</v-icon
              >
            </template>
            <span>Informations et Tarifs</span>
          </v-tooltip>
        </h4>
        <div class="d-flex align-center ">
          <inline-svg
            :src="require('../../../assets/map/ic_close.svg')"
            @click="$emit('close-popup')"
            class="icon"
            alt="icone fermeture"
            :fill="poisColors && poisColors.text ? poisColors.text : '#404040'"
          ></inline-svg>
        </div>
        <!-- <v-icon >fas fa-times</v-icon> -->
      </div>
      <div class="position d-flex">
        <!-- <v-icon class="mr-4">fas fa-map-marker-alt</v-icon> -->
        <span class="mt-auto">
          {{
            station[0].station.street_number !== "nan"
              ? station[0].station.street_number
              : null
          }}
          {{ station[0].station.street_name }}, {{ station[0].station.town }}
          {{ station[0].station.zip_code }}
          {{ country }}
        </span>
      </div>
    </section>
    <v-expand-transition>
      <section class="network-infos pa-4" v-if="networkInfos">
        <div class="d-flex justify-space-between">
          <p v-if="networkInfos.abonnement">
            Abonnement: {{ networkInfos.abonnement }}
          </p>
          <inline-svg
            :src="require('../../../assets/map/ic_close.svg')"
            @click="
              networkInfos = null;
              $emit('selected-emsp', null, false);
              selectedPoint.roaming = false;
            "
            class="icon"
            alt="icone fermeture"
            :fill="poisColors && poisColors.text ? poisColors.text : '#404040'"
          ></inline-svg>
        </div>
        <p v-if="networkInfos.prix_badge">
          Prix du badge: {{ networkInfos.prix_badge }}
        </p>
        <p class="mb-0" v-if="networkInfos.prix_transactions">
          Prix des charges:
          <span v-html="networkInfos.prix_transactions"></span>
        </p>
        <div class="actions d-flex mt-4">
          <v-btn
            :href="networkInfos.signup"
            target="_blank"
            dark
            small
            tile
            class="mr-2"
            >{{ $t("emsp.register") }}</v-btn
          >
          <v-btn
            :href="networkInfos.page"
            target="_blank"
            dark
            small
            tile
            class="mr-4"
            >{{ $t("emsp.details") }}</v-btn
          >
        </div>
      </section>
    </v-expand-transition>
    <v-expand-transition>
      <section
        class="problem-section d-flex align-center justify-center"
        v-if="isProblemModalOpen && problemSent"
      >
        <p
          style="color:green"
          class="mb-0 mt-2 d-flex flex-column align-center text-center"
        >
          <v-icon color="green" x-large class="mr-2 d-block mb-4"
            >fas fa-check</v-icon
          ><span
            >Votre signalement a bien été pris en compte<br />Nous vous en
            remercions.</span
          >
        </p>
      </section>
    </v-expand-transition>
    <v-expand-transition>
      <section
        v-if="isProblemModalOpen && !problemSent"
        class="problem-section"
      >
        <h4>Signaler un problème</h4>
        <v-form
          v-model="valid"
          @submit.prevent="validate"
          lazy-validation
          ref="form"
        >
          <v-text-field
            class="mt-4"
            outlined
            label="Votre adresse email"
            v-model="email"
            :rules="[...requiredRule, ...emailRule]"
          ></v-text-field>
          <v-select
            item-text="label"
            item-value="id"
            v-model="category"
            outlined
            :items="categorieList"
            :reduce="problem => problem.id"
            label="Catégorie"
            :rules="[...requiredRule]"
          />

          <label class="label">Description</label>
          <quill-editor
            ref="quill"
            v-model="description"
            :options="editorOptions"
            class="mb-4 editor"
          />

          <v-btn type="submit" small>Valider</v-btn>
        </v-form>
      </section>
    </v-expand-transition>
    <simplebar class="points-content" data-simplebar-auto-hide="false">
      <v-expansion-panels multiple accordion>
        <v-expansion-panel v-for="(data, name) in connectors" :key="name">
          <PopupConnectorList
            :poisColors="poisColors"
            :isRoaming="selectedPoint.roaming"
            :selectedPoint="selectedPoint"
            :data="data"
            :name="name"
          ></PopupConnectorList>
        </v-expansion-panel>
      </v-expansion-panels>
    </simplebar>
    <section class="actions d-none">
      <div class="charge pa-4">
        <img src="@/assets/map/ic_fast_white.svg" alt="icon borne rapide" />
        <!-- <v-icon color="white" class="action-icon">fas fa-bolt</v-icon> -->
        <span>{{ $t("popup.here") }}</span>
      </div>
      <div class="direction pa-4">
        <img src="@/assets/map/ic_direction.svg" alt="icon borne rapide" />
        <span>{{ $t("popup.direction") }}</span>
      </div>
    </section>
    <section class="ad-hoc-point-container d-none">
      <iframe
        class="point-iframe"
        :src="
          'https://pay.chargedrive.com/' +
            points[0].emi3.split('*')[0] +
            '*' +
            points[0].emi3.split('*')[1] +
            '*' +
            points[0].emi3.split('*')[2] +
            '*' +
            points[0].emi3.split('*')[3]
        "
      ></iframe>
    </section>
  </section>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import PopupConnectorList from "@/components/map/pin/popup/ConnectorList";
import { quillEditor } from "vue-quill-editor";
import { mapGetters } from "vuex";
export default {
  components: { quillEditor, PopupConnectorList },
  // props: ["selectedPoint", "poisColors"],
  props: {
    selectedPoint: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    isProblemModalOpen: false,
    valid: false,
    categorieList: [
      { id: 1, label: "Borne en panne" },
      { id: 2, label: "Borne vandalisée" },
      { id: 3, label: "Autre anomalie sur la borne" }
    ],
    requiredRule: [v => !!v || "Veuillez remplir ce champ"],
    emailRule: [v => /.+@.+/.test(v) || "Vérifiez le format du mail"],
    description: null,
    category: null,
    email: null,
    editorOptions: {
      modules: {
        toolbar: [
          [
            "bold",
            "italic",
            "underline",
            { list: "ordered" },
            { list: "bullet" },
            { color: [] },
            { background: [] },
            "link"
          ]
        ]
      },
      placeholder: "Décrivez le problème"
    },
    networkInfos: null,
    problemSent: false
  }),
  computed: {
    ...mapGetters("emsp", ["emsps"]),
    country() {
      switch (this.station[0].station.country) {
        case "FR":
          return "France";
        case "FRA":
          return "France";
        case "ITA":
          return "Italie";
        default:
          break;
      }
    },
    station() {
      return this.$store.getters["station/station"];
    },
    selectedEmsp() {
      return this.selectedPoint.emsp === "Indigo"
        ? "Parking Indigo"
        : this.selectedPoint.emsp === "null"
        ? this.station.cpo_name || this.station.cpo.name
        : this.$store.getters["emsp/emsp"] &&
          this.selectedPoint.emsp === this.$store.getters["emsp/emsp"].emsp_id
        ? this.$store.getters["emsp/emsp"].name
        : this.selectedPoint.emsp;
    },
    points() {
      let points = JSON.parse(
        JSON.stringify(this.$store.getters["station/station"])
      );
      return points;
    },

    connectors() {
      let points = this.$store.getters["station/station"];
      let standards = new Set();
      points.forEach(cp => {
        cp.connectors.forEach(connector => {
          connector.connector_standard.forEach(item => {
            standards.add(JSON.stringify(item));
          });
        });
      });
      let finals = [];
      standards.forEach(standard => {
        finals.push({
          standard: JSON.parse(standard),
          list: []
        });
      });
      points.forEach(cp => {
        cp.connectors.forEach(connector => {
          finals.forEach(type => {
            if (connector.standard === type.standard.type)
              type.list.push({
                ...connector,
                type_charge: cp.type_charge,
                status: cp.status
              });
          });
        });
      });
      return finals;
    },
    emspsOption() {
      return this.$store.getters["emsp/emspOption"];
    },
    poisColors() {
      return { roaming: "#fff200", pois: "#59b570", text: "#141417" };
    }
  },
  methods: {
    checkPowerType(point) {
      let powerType = "Normale";

      if (point.power >= 36 || point.type_charge === "rapide")
        powerType = this.$t("popup.fast");
      else {
        point.connectors.forEach(connector => {
          if (connector.amperage > 64) powerType = this.$t("popup.fast");
        });
      }
      return powerType;
    },
    checkConsumption(connectors) {
      let highestPower = 0;
      connectors.forEach(connector => {
        if (
          Math.trunc((connector.amperage * connector.voltage) / 1000) >
          highestPower
        )
          highestPower = Math.trunc(
            (connector.amperage * connector.voltage) / 1000
          );
      });

      return highestPower;
    },

    async validate() {
      if (this.valid) {
        await this.$store.dispatch("station/addProblemeReport", {
          station_id: this.station_id,
          email: this.email,
          category: this.category,
          description: this.description
        });
        this.description = null;
        this.category = null;
        this.email = null;
        this.problemSent = true;
      }
    },
    async showInfos() {
      this.emspsOption.forEach(emspOption => {
        let option = emspOption.acf;
        if (this.selectedPoint.emsp === option.slug) {
          this.networkInfos = option;
          let emsp = this.emsps.find(
            emsp => emsp.emsp_id === this.selectedPoint.emsp
          );
          this.networkInfos.signup = emsp.signup;
          this.networkInfos.page = emsp.page;
          this.selectedPoint.roaming = true;
          this.$emit("selected-emsp", emsp, false);
          this.$router.push("/" + emsp.emsp_id);
        }
      });
    }
  }
};
</script>

<style lang="scss">
.problem-section {
  background: white;
  padding: 0.85em 1em;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  z-index: 2;
  margin-top: -6px;
  padding-bottom: 2em;
}
.charging-station {
  position: relative;
  border-radius: 6px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.33);
  z-index: 2;
  .station-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top: 1px solid #eee;
    h4 {
      text-transform: uppercase;
    }
    height: 25%;
    color: #141417;
    font-size: 1.25em;
    span {
      font-size: 0.75em;
    }
    .position {
      .position-icon {
        width: 20px;
        height: 20px;
        margin-right: 0.5em;
      }
      span {
        font-size: 0.65em;
      }
    }
  }
  .inside-panel {
    padding: 10px;
    .connector {
      font-size: 15px;
      width: 100%;
      padding: 0 0 0 15px;
      background: #eee;
      border-radius: 10px;
      .connector-content {
        padding: 10px 0;
      }
      .status {
        margin-bottom: 0.5em;
      }
      p {
        margin: 0;
      }
      .button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        text-transform: uppercase;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.54);
        font-size: 0.85em;
        background: #ddd;
        height: 100px;
        padding: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        .icon {
          margin-bottom: 0.25em;
          margin-left: 0.25em;
        }
      }
      .connector-link {
        cursor: pointer;
      }
      .dot {
        content: "";
        background: #ccc;
        height: 12px;
        width: 12px;
        padding: 0;
        font-size: 1rem;
        box-sizing: border-box;
        border-radius: 12px;
        display: block;
        margin-right: 1em;
      }
    }
  }
  .connecteurs {
    font-size: 20px;
    font-family: "SCHUKO";
    span {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
    img {
      width: 7;
      height: 50px;
    }
  }

  .evseid {
    padding: 0.6em;
    font-size: 1em;
    display: flex;
    align-items: center;
    span.dot {
      content: "";
      background: #ccc;
      height: 12px;
      width: 12px;
      padding: 0;
      font-size: 1rem;
      box-sizing: border-box;
      border-radius: 12px;
      display: block;
      margin-right: 1em;
    }
  }
  .points-content {
    box-sizing: content-box;
    max-height: 300px;
    position: relative;
    z-index: 2;
    overflow: auto;
    border-top: 1px solid #eee;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-top: -6px;
  }
  .points-content,
  .point-content {
    .v-expansion-panel-header {
      padding: 0.15em 1em 0.15em 0.5em;
      .v-icon {
        font-size: 15px;
      }
    }
    .v-expansion-panel-content__wrap {
      padding: 1.25em;
      padding-top: 0.75em;
      p {
        font-size: 0.9em;
        margin: 0.5em 0;
        font-weight: 400;
        display: flex;
        align-items: center;
        span {
          font-weight: 700;
          margin-left: 0.5em;
          &:first-child {
            font-weight: normal;
          }
          &.plug-format {
            font-family: "SCHUKO";
            font-size: 1.25em;
          }
        }
      }
    }
  }

  .actions {
    .charge,
    .direction {
      width: 50%;
      cursor: pointer;
      position: relative;
      align-items: center;
      justify-content: center;
      display: flex;
      img {
        position: absolute;
        left: 10%;
        width: 20px;
        height: 20px;
      }
      span {
        text-transform: uppercase;
        font-size: 14px;
      }
    }

    .charge {
      background: #5886cd;
      color: white;
    }
    .action-icon {
      position: absolute;
      width: 30px;
      height: 30px;
      top: calc(50% - 15px);
      left: 16px;
    }
    .direction {
      background: #efefef;
    }
  }
  .point-iframe {
    width: 100%;
    height: 400px;
  }
  .green-dot {
    span.dot {
      background: #59b570;
    }
  }
  .blue-dot {
    span.dot {
      background: #1f73b4;
    }
  }
  .icon {
    cursor: pointer;
    width: 15px;
    height: 15px;
  }

  .bg-green {
    background: #59b570;
  }
  .bg-yellow {
    background: #fff200;
  }
}
#app .problem-modal.theme--light.v-card {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.33);
  border-radius: 6px;
  label {
    font-size: 14px;
  }
}
.ql-container {
  font-size: 14px;
  min-height: 150px;
}
.network-infos {
  background: white;
}
.info-icon {
  margin-left: auto;
  width: 18px;
  height: 18px !important;
  min-width: 18px !important;
  padding: 0 !important;
  //border: 1px solid #ddd !important;
  box-shadow: none; /*0px 0px 2px 0px rgba(0,0,0,.5);*/
  img {
    width: 12px;
  }
}
#app .v-tooltip__content {
  transform: translateY(-3px);
  background: rgba(97, 97, 97, 1);
  font-size: 0.75em;
  padding: 2px 8px;
  color: rgba(0, 0, 0, 0.87);
}
</style>

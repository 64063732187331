<template>
  <v-container class="admin-index">
    <v-row><h3 class="text-center title">Espace administrateur</h3></v-row>
    <v-row>
      <v-col cols="4">
        <v-card elevation="2" height="230px" hover>
          <v-row
            class="module d-flex flex-column"
            align="center"
            justify="center"
          >
            <span>Consultations</span>
            <v-btn @click="drawer = !drawer" icon>
              <v-icon>fas fa-arrow-right</v-icon>
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-navigation-drawer v-model="drawer" absolute temporary right width="100%">
      <!-- <CreateConsultation @close="drawer = false"></CreateConsultation> -->
      <Consultations  @close="drawer = false"></Consultations>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import CreateConsultation from "@/components/admin/CreateConsultation";
import Consultations from "@/components/admin/Consultations";
export default {
  components: { CreateConsultation, Consultations },
  data: () => ({
    drawer: false
  })
};
</script>

<style lang="scss">
.admin-index {
  height: 100vh;
  .title {
    width: 100%;
    margin-bottom: 10vh;
  }
  .module {
    height: 100%;
  }
}
</style>

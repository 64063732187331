<template>
  <v-dialog v-model="isModalOpen" max-width="320">
    <v-card>
      <v-toolbar>{{title}}</v-toolbar>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="$emit('close')"
              >Fermer</v-btn>
              <v-btn
                text
                @click="$emit('validate')"
              >Valider</v-btn>
            </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isModalOpen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Êtes vous sur de vouloir supprimer cet objet ?"
    },
  },
}
</script>

<style>

</style>
